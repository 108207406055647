import React from "react";
import styled from "styled-components";
import { Textfit } from "react-textfit";
import { getFontStyles } from "./utils";

const BlockContainer = styled.div`
  position: relative;
  width: ${p => p.width}px;
  /* margin: ${p => 10 * p.scale}px 0px; */
  box-sizing: border-box;
`;

const Text = styled(Textfit)`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3 ease-out;
  /* text-transform: uppercase; */
  font-weight: ${p => (p.top ? "bold" : 400)};
  color: ${p => p.foregroundColor};
  text-align: center;
  /* height: ${p => 40 * p.scale}px; */
  margin: 0px;
  
  /* padding-top: ${p => (p.bottom ? 5 * p.scale : 0)}px; */
  /* border-top: ${p =>
    p.bottom ? `${1 * p.scale}px solid ${p.foregroundColor}` : "none"}; */
`;

const PosterTextBlock = ({
  textTop,
  textBottom,
  scale,
  width,
  textStyle,
  foregroundColor,
  onSelect,
  onSizeChange,
  position,
  style,
  size
}) => {
  const offsetV = size === "18x18" ? -10 : -30;
  const offset =
    position === "top" ? { top: offsetV * scale } : { bottom: offsetV * scale };

  const rectMultiplier = (size === "18x18" ? 1 : 1.3) + (!textBottom ? 0.3 : 0);
  const tmin = 14 * scale * rectMultiplier;
  const tmax = 60 * scale * rectMultiplier;
  const bmin = 10 * scale * rectMultiplier;
  const bmax = 18 * scale * rectMultiplier;
  const margin = 16 * scale * rectMultiplier;

  const handleSelect = position => () => onSelect(position);

  const handleReady = d => {
    if (onSizeChange) onSizeChange(d);
  };

  return (
    <BlockContainer
      position={position}
      width={width}
      scale={scale}
      style={{ ...style, ...offset }}
    >
      <Text
        foregroundColor={foregroundColor}
        min={tmin}
        max={tmax}
        mode="single"
        scale={scale}
        standalone={!textBottom}
        textStyle={textStyle}
        onClick={handleSelect("top")}
        onReady={handleReady}
        style={{ ...getFontStyles(textStyle), fontWeight: 900 }}
        top
      >
        {textTop}
      </Text>
      {textBottom ? (
        <Text
          foregroundColor={foregroundColor}
          min={bmin}
          max={bmax}
          mode="single"
          scale={scale}
          textStyle={textStyle}
          onClick={handleSelect("bottom")}
          style={{
            ...getFontStyles(textStyle),
            fontWeight: 100
            // marginTop: margin
          }}
          bottom
        >
          {textBottom}
        </Text>
      ) : null}
    </BlockContainer>
  );
};

export default PosterTextBlock;
