import hash from "object-hash"
import pick from "lodash/pick"
import { baseSize } from "./data"

export const getFont = style => {
  switch (style) {
    case "serif":
      return "Freight"
    case "sans":
      return "Gotham Pro"
    case "script":
      return "Great Vibes"
  }
}

export const getScaleDown = (displayWidth, { framed }) => {
  const width = baseSize + (framed ? (baseSize / 18) * 0.75 : 0)
  let scaleDown = width > displayWidth ? displayWidth / width : 1.0
  return scaleDown
}

export const createObjectHash = (obj, keys = []) => {
  const data = keys.length ? pick(obj, keys) : obj
  return hash(data)
}

export const getFontStyles = style => {
  switch (style) {
    case "serif":
      return { fontFamily: "Freight", textTransform: "uppercase" }
    case "sans":
      return {
        fontFamily: "Gotham Pro ",
        textTransform: "uppercase"
      }
    case "script":
      return { fontFamily: "Great Vibes", letterSpacing: 1.3 }
  }
}
