import React from "react"
import styled from "styled-components"
import Map from "../map/Map"

const Mask = styled.div`
  position: absolute;
  left: ${p => p.borderWidth * 2}px;
  top: ${p => p.borderWidth * 2}px;
  width: ${p => p.radius * 2}px;
  height: ${p => p.radius * 2}px;
  /* clip-path: ${p => `circle(${p.radius}px at center)`};
  -webkit-clip-path: ${p => `circle(${p.radius}px at center)`};
  -moz-clip-path: ${p => `circle(${p.radius}px at center)`}; */
`

const SquareContainer = styled.div`
  width: ${p => p.size + p.borderWidth * 2}px;
  height: ${p => p.size + p.borderWidth * 2}px;
  border: ${p =>
    p.showBorder
      ? `${Math.floor(p.borderWidth)}px solid ${p.borderColor}`
      : `0px`};
  /* border-radius: ${p => p.size}px; */
  position: relative;
  pointer-events: ${p => (p.interactive ? "auto" : "none")};
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: all 0.3s ease-out;
`

class MapSquare extends React.PureComponent {
  render() {
    const size = this.props.radius * 2
    const borderWidth =
      this.props.borderWidth *
      this.props.scale *
      (this.props.chonky ? 1.5 : 1.1)
    const borderColor = this.props.accentColor || this.props.foregroundColor
    return (
      <SquareContainer
        size={size}
        borderColor={borderColor}
        borderWidth={borderWidth}
        showBorder={this.props.showBorder}
        interactive={this.props.interactive}
      >
        <Mask radius={this.props.radius}>
          <Map
            {...this.props}
            location={this.props.location}
            zoom={this.props.zoom}
            buffer={this.props.buffer}
            width={size}
            height={size}
            scale={this.props.scalePerc}
            onChange={this.props.onChange}
            backgroundColor={"transparent"}
            foregroundColor={this.props.foregroundColor}
            readonly={this.props.readonly}
            overlayImage={this.props.overlayImage}
            pitch={this.props.pitch}
          />
        </Mask>
        {this.props.children ? this.props.children : null}
      </SquareContainer>
    )
  }
}

MapSquare.defaultProps = {
  borderWidth: 2,
  interactive: true,
  overlayImage: true,
  showBorder: true
}

export default MapSquare
