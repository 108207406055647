import React from "react";
import * as Icon from "react-feather";
import { getIconName } from "./poster-utils";

const ImageIcon = ({ icon, size }) => {
  return <img src={require(icon)} width={size} alt={icon} />;
};

class PosterIcon extends React.PureComponent {
  renderImageIcon() {
    return <ImageIcon icon={this.props.icon} size={this.props.size} />;
  }

  renderFeatherIcon() {
    const {
      backgroundColor,
      foregroundColor,
      accentColor,
      icon,
      size,
      chonky
    } = this.props;
    if (icon === "") return null;
    const strokeSize = 1 * chonky ? 2 : 1;
    const iconName = getIconName(icon);
    const IconComponent = Icon[iconName] || null;
    return (
      <>
        <div style={{ position: "absolute", left: 0, top: 0 }}>
          <IconComponent
            strokeWidth={strokeSize}
            size={size}
            style={{ color: backgroundColor }}
          />
        </div>

        <div style={{ position: "absolute", left: 0, top: 0 }}>
          <IconComponent
            strokeWidth={strokeSize / 2}
            size={size}
            style={{ color: accentColor || foregroundColor }}
          />
        </div>
      </>
    );
  }

  render() {
    return (
      <div
        style={{
          position: "relative",
          width: this.props.size,
          height: this.props.size,
          pointerEvents: "none",
          zIndex: 10000
        }}
      >
        {" "}
        {this.props.icon.indexOf(".svg") > -1
          ? this.renderImageIcon()
          : this.renderFeatherIcon()}
      </div>
    );
  }
}

export default PosterIcon;
