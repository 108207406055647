import React from "react"
import styled from "styled-components"
import { getFontStyles } from "./utils"
import { baseOuterFontSize, calcFontCircleRadius } from "./data"

const Char = styled.span`
  font-size: ${p => p.size}px;
  font-weight: bold;
  font-weight: 900;
  /* text-transform: uppercase; */
  height: ${p => p.radius}px;
  position: absolute;
  width: ${p => p.size}px;
  left: ${p => p.radius}px;
  text-align: center;
  top: 0px;
  transform-origin: bottom center;
  color: ${p => p.color};
  pointer-events: none;
  // border: 1px solid #fff;
  span {
    display: block;
    margin-top: ${p => (p.inverted ? -1 : 0)}px;
    transform: ${p => (p.inverted ? `scale(1,-1)` : "")};
  }
`

const CircleTextWrap = styled.div`
  position: absolute;
  left: 0px;
  width: ${p => p.radius * 2}px;
  height: ${p => p.radius * 2}px;
  top: ${p => p.fontHeightBuffer * 0.75}px;
  pointer-events: none;
`

const CircleText = ({
  text,
  radius,
  inverted,
  color,
  textStyle,
  fontSize,
  scale = 1.0
}) => {
  const circleMargin = 10
  const deg = 360 / 2 - circleMargin
  const fontHeightBuffer = 10 * scale
  const fontHeight = fontSize + fontHeightBuffer

  const circleRadius = calcFontCircleRadius(radius, scale)
  const rotation = -(deg / 2)
  const transform = `${`rotate(${rotation}deg)`}${
    inverted ? ` scale(1,-1)` : ""
  }`
  return (
    <CircleTextWrap
      rotation={rotation}
      radius={circleRadius}
      scale={scale}
      fontHeight={fontHeight}
      fontHeightBuffer={fontHeightBuffer}
      inverted={inverted}
      style={{ transform }}
    >
      {text.split("").map((c, i) => {
        const charAngle = deg / text.length
        let rotation = charAngle / 2 + charAngle * i
        if (inverted) rotation -= 180 - circleMargin

        return (
          <Char
            key={i}
            rotation={rotation}
            size={fontSize * scale}
            color={color}
            inverted={inverted}
            radius={circleRadius}
            textStyle={textStyle}
            style={{
              transform: `rotate(${rotation}deg)`,
              ...getFontStyles(textStyle)
            }}
          >
            <span>{c}</span>
          </Char>
        )
      })}
    </CircleTextWrap>
  )
}

CircleText.defaultProps = {
  fontSize: baseOuterFontSize,
  text: ""
}

export default CircleText
