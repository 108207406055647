// export const defaultState = {
//   location: {
//     lng: -73.98172596787782,
//     lat: 40.73357545004572
//   },
//   textTop: "New York City",
//   textBottom: "",
//   showBorder: true,
//   showSecondaryRoads: true,
//   zoom: 11.52659119077374,
//   backgroundColor: "#13181F", // #861007
//   foregroundColor: "#FFF2A7", // #fff2a7
//   iconURL: null,
//   icon: null,
//   textLayout: "block",
//   textStyle: "sans",
//   // UI state
//   showIntro: true,
//   locationLabel: ""
// }

const base = 500
export const baseSize = base
export const baseScale = 1
export const baseOuterFontSize = 14
export const baseInnerFontSize = 38

export const calcFontCircleRadius = (radius, scale) => {
  const fontSize = baseOuterFontSize * scale
  const fontHeightBuffer = 10 * scale
  const fontHeight = fontSize + fontHeightBuffer
  return radius + fontHeight
}
