import color from "color";
import defaults from "lodash/defaults";

const getStrokeSize = strokeSize => {
  return {
    base: strokeSize,
    stops: [[0, strokeSize * 1.5], [11, strokeSize * 1.5]]
  };
};

export const getMapStyle = props => {
  props = defaults(props, {
    backgroundColor: "#ffffff",
    foregroundColor: "#000000",
    showSecondaryRoads: true
  });

  const baseStrokeSize = props.chonky ? 2 : 1.3;
  // TODO: replace this w/ values from stark.json

  const { foregroundColor, backgroundColor, showSecondaryRoads } = props;

  let foregroundLight = color(foregroundColor)
    .alpha(0.25)
    .rgb()
    .toString();

  const accentColor = props.accentColor || foregroundLight;

  //let backgroundColor = color(backgroundColor).alpha(0.33).hsl().toString()

  return {
    foregroundColor,
    backgroundColor,
    version: 8,
    name: "Decimal",
    metadata: {
      "mapbox:trackposition": false,
      "mapbox:groups": {
        a636a5194689e7c1dede11bf3cd8dcac: {
          name: "Landuse",
          collapsed: true
        },
        "0cb8c63458cbe21bbe1ad66543f73a04": {
          name: "Roads",
          collapsed: true
        },
        ab588f3c9c75b626577112146960e2bf: {
          name: "Tunnels",
          collapsed: true
        },
        "4a585425cb0cc2af93b4cdedda86461d": {
          name: "Borders",
          collapsed: false
        },
        f423319a6dab2ed463382f7e7edc5da1: {
          name: "Bridges",
          collapsed: true
        },
        "003e472752e5683c3f8ef9dee45b54a0": {
          name: "Misc",
          collapsed: true
        }
      },
      "mapbox:autocomposite": true
    },
    center: [-73.99186566152349, 40.727020835966215],
    zoom: 13.197221148965,
    bearing: 0,
    pitch: 0,
    light: {
      color: "white", //color(foregroundColor).whiten(0.75).hsl().toString(),
      intensity: 0.5
    },
    sources: {
      composite: {
        url: "mapbox://mapbox.mapbox-streets-v7",
        type: "vector"
      }
    },
    sprite: "mapbox://sprites/wmdmark/cj5sctll40mwn2smt9y7e8su1",
    glyphs: "mapbox://fonts/wmdmark/{fontstack}/{range}.pbf",
    layers: [
      {
        id: "background",
        type: "background",
        layout: {
          visibility: "visible"
        },
        paint: {
          "background-color": backgroundColor
        }
      },
      {
        id: "schools",
        type: "fill",
        metadata: {
          "mapbox:group": "a636a5194689e7c1dede11bf3cd8dcac"
        },
        source: "composite",
        "source-layer": "landuse",
        filter: ["all", ["==", "$type", "Polygon"], ["==", "class", "school"]],
        layout: {
          visibility: "none"
        },
        paint: {
          "fill-color": backgroundColor,
          "fill-antialias": true,
          "fill-opacity": 0.75
        }
      },
      {
        id: "hospitals",
        type: "fill",
        metadata: {
          "mapbox:group": "a636a5194689e7c1dede11bf3cd8dcac"
        },
        source: "composite",
        "source-layer": "landuse",
        filter: [
          "all",
          ["==", "$type", "Polygon"],
          ["==", "class", "hospital"]
        ],
        layout: {
          visibility: "visible"
        },
        paint: {
          "fill-color": backgroundColor,
          "fill-antialias": true,
          "fill-opacity": 0.75
        }
      },
      {
        id: "cemeteries",
        type: "fill",
        metadata: {
          "mapbox:group": "a636a5194689e7c1dede11bf3cd8dcac"
        },
        source: "composite",
        "source-layer": "landuse",
        minzoom: 10,
        filter: [
          "all",
          ["==", "$type", "Polygon"],
          ["==", "class", "cemetery"]
        ],
        layout: {},
        paint: {
          "fill-color": backgroundColor,
          "fill-opacity": 0.75,
          "fill-antialias": true
        }
      },
      {
        id: "parks",
        type: "fill",
        metadata: {
          "mapbox:group": "a636a5194689e7c1dede11bf3cd8dcac"
        },
        source: "composite",
        "source-layer": "landuse",
        filter: ["==", "class", "park"],
        layout: {},
        paint: {
          "fill-color": backgroundColor,
          "fill-opacity": {
            base: 1,
            stops: [[5, 0], [6, 0.75]]
          }
        }
      },
      {
        id: "sand",
        type: "fill",
        metadata: {
          "mapbox:group": "a636a5194689e7c1dede11bf3cd8dcac"
        },
        source: "composite",
        "source-layer": "landuse",
        filter: ["==", "class", "sand"],
        layout: {},
        paint: {
          "fill-color": backgroundColor
        }
      },
      {
        id: "water",
        type: "fill",
        metadata: {
          "mapbox:group": "a636a5194689e7c1dede11bf3cd8dcac"
        },
        source: "composite",
        "source-layer": "water",
        layout: {},
        paint: {
          "fill-color": accentColor,
          "fill-outline-color": accentColor
        }
      },
      {
        id: "water-outline",
        type: "line",
        metadata: {
          "mapbox:group": "a636a5194689e7c1dede11bf3cd8dcac"
        },
        source: "composite",
        "source-layer": "water",
        layout: {},
        paint: {
          "line-width": getStrokeSize(baseStrokeSize * 0.9),
          "line-color": foregroundColor,
          "line-opacity": 1
        }
      },
      {
        id: "aeroway-polygon",
        type: "fill",
        metadata: {
          "mapbox:group": "003e472752e5683c3f8ef9dee45b54a0"
        },
        source: "composite",
        "source-layer": "aeroway",
        minzoom: 11,
        filter: ["all", ["!=", "type", "apron"], ["==", "$type", "Polygon"]],
        layout: {},
        paint: {
          "fill-color": backgroundColor,
          "fill-opacity": {
            base: 1,
            stops: [[11, 0], [11.5, 1]]
          },
          "fill-outline-color": foregroundColor
        }
      },
      {
        id: "aeroway-runway",
        type: "line",
        metadata: {
          "mapbox:group": "003e472752e5683c3f8ef9dee45b54a0"
        },
        source: "composite",
        "source-layer": "aeroway",
        minzoom: 9,
        filter: [
          "all",
          ["==", "$type", "LineString"],
          ["==", "type", "runway"]
        ],
        layout: {
          visibility: "visible"
        },
        paint: {
          "line-color": backgroundColor,
          "line-width": {
            base: 1.5,
            stops: [[9, 0.75], [18, 1]]
          }
        }
      },
      {
        id: "aeroway-taxiway",
        type: "line",
        metadata: {
          "mapbox:group": "003e472752e5683c3f8ef9dee45b54a0"
        },
        source: "composite",
        "source-layer": "aeroway",
        minzoom: 9,
        filter: [
          "all",
          ["==", "$type", "LineString"],
          ["==", "type", "taxiway"]
        ],
        layout: {
          visibility: "visible"
        },
        paint: {
          "line-color": backgroundColor,
          "line-width": {
            base: 1.5,
            stops: [[10, 0.5], [18, 12]]
          }
        }
      },
      {
        id: "building",
        type: "fill",
        metadata: {
          "mapbox:group": "003e472752e5683c3f8ef9dee45b54a0"
        },
        source: "composite",
        "source-layer": "building",
        minzoom: 13,
        filter: ["==", "underground", "false"],
        layout: {},
        paint: {
          "fill-color": backgroundColor,
          "fill-antialias": true,
          "fill-outline-color": foregroundColor
        }
      },
      {
        id: "building-outline",
        type: "line",
        metadata: {
          "mapbox:group": "003e472752e5683c3f8ef9dee45b54a0"
        },
        source: "composite",
        "source-layer": "building",
        minzoom: 13,
        filter: ["==", "underground", "false"],
        layout: {},
        paint: {
          "line-width": getStrokeSize(baseStrokeSize),
          "line-color": foregroundColor,
          "line-opacity": 1,
          "line-dasharray": [1, 0]
        }
      },
      {
        id: "tunnel-trunk-link",
        type: "line",
        metadata: {
          "mapbox:group": "ab588f3c9c75b626577112146960e2bf"
        },
        source: "composite",
        "source-layer": "road",
        minzoom: 13,
        filter: [
          "all",
          ["==", "structure", "tunnel"],
          ["==", "type", "trunk_link"]
        ],
        layout: {
          "line-cap": "round",
          "line-join": "round"
        },
        paint: {
          "line-width": {
            base: 1.5,
            stops: [[12, 0.5], [14, 1], [18, 1]]
          },
          "line-color": foregroundColor,
          "line-opacity": 1,
          "line-dasharray": [1, 0]
        }
      },
      {
        id: "tunnel-motorway-link",
        type: "line",
        metadata: {
          "mapbox:group": "ab588f3c9c75b626577112146960e2bf"
        },
        source: "composite",
        "source-layer": "road",
        minzoom: 13,
        filter: [
          "all",
          ["==", "class", "motorway_link"],
          ["==", "structure", "tunnel"]
        ],
        layout: {
          "line-cap": "round",
          "line-join": "round"
        },
        paint: {
          "line-width": {
            base: 1.5,
            stops: [[12, 0.5], [14, 1], [18, 1]]
          },
          "line-color": foregroundColor,
          "line-opacity": 1,
          "line-dasharray": [1, 0]
        }
      },
      {
        id: "tunnel-pedestrian",
        type: "line",
        metadata: {
          "mapbox:group": "ab588f3c9c75b626577112146960e2bf"
        },
        source: "composite",
        "source-layer": "road",
        minzoom: 13,
        filter: [
          "all",
          ["==", "$type", "LineString"],
          ["all", ["==", "class", "pedestrian"], ["==", "structure", "tunnel"]]
        ],
        layout: {
          "line-join": "round"
        },
        paint: {
          "line-width": {
            base: 1.5,
            stops: [[14, 0.5], [18, 1]]
          },
          "line-color": foregroundLight,
          "line-opacity": 0.5,
          "line-dasharray": {
            base: 1,
            stops: [[14, [1, 0]], [15, [1.5, 0.4]], [16, [1, 0.2]]]
          }
        }
      },
      {
        id: "tunnel-service-link-track",
        type: "line",
        metadata: {
          "mapbox:group": "ab588f3c9c75b626577112146960e2bf"
        },
        source: "composite",
        "source-layer": "road",
        minzoom: 14,
        filter: [
          "all",
          ["!=", "type", "trunk_link"],
          ["==", "structure", "tunnel"],
          ["in", "class", "link", "service", "track"]
        ],
        layout: {
          "line-cap": "round",
          "line-join": "round"
        },
        paint: {
          "line-width": {
            base: 1.5,
            stops: [[14, 0.5], [18, 1]]
          },
          "line-color": foregroundLight,
          "line-dasharray": [1, 0],
          "line-opacity": 0.5
        }
      },
      {
        id: "tunnel-street",
        type: "line",
        metadata: {
          "mapbox:group": "ab588f3c9c75b626577112146960e2bf"
        },
        source: "composite",
        "source-layer": "road",
        minzoom: 11,
        filter: [
          "all",
          ["==", "structure", "tunnel"],
          ["in", "class", "street", "street_limited"]
        ],
        layout: {
          "line-cap": "round",
          "line-join": "round"
        },
        paint: {
          "line-width": {
            base: 1.5,
            stops: [[12.5, 0.5], [14, 2], [18, 1]]
          },
          "line-color": foregroundLight,
          "line-opacity": 0.5
        }
      },
      {
        id: "tunnel-secondary-tertiary",
        type: "line",
        metadata: {
          "mapbox:group": "ab588f3c9c75b626577112146960e2bf"
        },
        source: "composite",
        "source-layer": "road",
        filter: [
          "all",
          ["==", "structure", "tunnel"],
          ["in", "class", "secondary", "tertiary"]
        ],
        layout: {
          "line-cap": "round",
          "line-join": "round"
        },
        paint: {
          "line-width": {
            base: 1.5,
            stops: [[8.5, 0.5], [10, 0.75], [18, 1]]
          },
          "line-color": foregroundLight,
          "line-opacity": 1,
          "line-dasharray": [1, 0]
        }
      },
      {
        id: "tunnel-primary",
        type: "line",
        metadata: {
          "mapbox:group": "ab588f3c9c75b626577112146960e2bf"
        },
        source: "composite",
        "source-layer": "road",
        filter: [
          "all",
          ["==", "class", "primary"],
          ["==", "structure", "tunnel"]
        ],
        layout: {
          "line-cap": "round",
          "line-join": "round"
        },
        paint: {
          "line-width": getStrokeSize(baseStrokeSize * 0.75),
          "line-color": foregroundLight,
          "line-opacity": 1,
          "line-dasharray": [1, 0]
        }
      },
      {
        id: "tunnel-trunk",
        type: "line",
        metadata: {
          "mapbox:group": "ab588f3c9c75b626577112146960e2bf"
        },
        source: "composite",
        "source-layer": "road",
        filter: [
          "all",
          ["==", "class", "trunk"],
          ["==", "structure", "tunnel"]
        ],
        layout: {
          "line-cap": "round",
          "line-join": "round"
        },
        paint: {
          "line-width": getStrokeSize(baseStrokeSize * 0.75),
          "line-color": foregroundColor
        }
      },
      {
        id: "tunnel-motorway",
        type: "line",
        metadata: {
          "mapbox:group": "ab588f3c9c75b626577112146960e2bf"
        },
        source: "composite",
        "source-layer": "road",
        filter: [
          "all",
          ["==", "class", "motorway"],
          ["==", "structure", "tunnel"]
        ],
        layout: {
          "line-cap": "round",
          "line-join": "round"
        },
        paint: {
          "line-width": getStrokeSize(baseStrokeSize * 0.75),
          "line-dasharray": [1, 0],
          "line-opacity": 1,
          "line-color": foregroundColor
        }
      },
      {
        id: "road-trunk-link",
        type: "line",
        metadata: {
          "mapbox:group": "0cb8c63458cbe21bbe1ad66543f73a04"
        },
        source: "composite",
        "source-layer": "road",
        minzoom: 11,
        filter: [
          "all",
          ["!in", "structure", "bridge", "tunnel"],
          ["==", "type", "trunk_link"]
        ],
        layout: {
          "line-cap": "round",
          "line-join": "round"
        },
        paint: {
          "line-width": getStrokeSize(baseStrokeSize),
          "line-color": foregroundLight,
          "line-opacity": 1
        }
      },
      {
        id: "road-motorway-link",
        type: "line",
        metadata: {
          "mapbox:group": "0cb8c63458cbe21bbe1ad66543f73a04"
        },
        source: "composite",
        "source-layer": "road",
        minzoom: 10,
        filter: [
          "all",
          ["!in", "structure", "bridge", "tunnel"],
          ["==", "class", "motorway_link"]
        ],
        layout: {
          "line-cap": "round",
          "line-join": "round"
        },
        paint: {
          "line-width": getStrokeSize(baseStrokeSize),
          "line-color": foregroundColor,
          "line-opacity": 1
        }
      },
      {
        id: "road-service-link-track",
        type: "line",
        metadata: {
          "mapbox:group": "0cb8c63458cbe21bbe1ad66543f73a04"
        },
        source: "composite",
        "source-layer": "road",
        minzoom: 14,
        filter: [
          "all",
          ["!=", "type", "trunk_link"],
          ["!in", "structure", "bridge", "tunnel"],
          ["in", "class", "link", "service", "track"]
        ],
        layout: {
          "line-join": "round",
          "line-cap": "round",
          visibility: "visible"
        },
        paint: {
          "line-width": {
            base: baseStrokeSize,
            stops: [[14, baseStrokeSize / 1.5], [18, baseStrokeSize]]
          },
          "line-color": foregroundColor
        }
      },
      {
        id: "road-street",
        type: "line",
        metadata: {
          "mapbox:group": "0cb8c63458cbe21bbe1ad66543f73a04"
        },
        source: "composite",
        "source-layer": "road",
        minzoom: 11,
        filter: [
          "all",
          ["==", "$type", "LineString"],
          [
            "all",
            ["==", "structure", "none"],
            ["in", "class", "street", "street_limited"]
          ]
        ],
        layout: {
          "line-cap": "round",
          "line-join": "round",
          visibility: showSecondaryRoads ? "visible" : "none"
        },
        paint: {
          "line-width": getStrokeSize(baseStrokeSize * 0.3),
          "line-color": foregroundColor,
          "line-opacity": 1
        }
      },
      {
        id: "road-secondary-tertiary",
        type: "line",
        metadata: {
          "mapbox:group": "0cb8c63458cbe21bbe1ad66543f73a04"
        },
        source: "composite",
        "source-layer": "road",
        filter: [
          "all",
          ["!in", "structure", "bridge", "tunnel"],
          ["in", "class", "secondary", "tertiary"]
        ],
        layout: {
          "line-cap": "round",
          "line-join": "round",
          visibility: showSecondaryRoads ? "visible" : "none"
        },
        paint: {
          "line-width": getStrokeSize(baseStrokeSize * 0.33),
          "line-color": foregroundLight,
          "line-opacity": 1
        }
      },
      {
        id: "road-primary-case",
        type: "line",
        metadata: {
          "mapbox:group": "0cb8c63458cbe21bbe1ad66543f73a04"
        },
        source: "composite",
        "source-layer": "road",
        filter: [
          "all",
          ["!in", "structure", "bridge", "tunnel"],
          ["==", "class", "primary"]
        ],
        layout: {
          "line-cap": "round",
          "line-join": "round",
          visibility: "visible"
        },
        paint: {
          "line-width": getStrokeSize(baseStrokeSize),
          "line-color": foregroundLight
        }
      },
      {
        id: "road-primary",
        type: "line",
        metadata: {
          "mapbox:group": "0cb8c63458cbe21bbe1ad66543f73a04"
        },
        source: "composite",
        "source-layer": "road",
        filter: [
          "all",
          ["!in", "structure", "bridge", "tunnel"],
          ["==", "class", "primary"]
        ],
        layout: {
          "line-cap": "round",
          "line-join": "round",
          visibility: "visible"
        },
        paint: {
          "line-width": getStrokeSize(baseStrokeSize * 1.3),
          "line-color": foregroundColor
        }
      },
      {
        id: "road-trunk",
        type: "line",
        metadata: {
          "mapbox:group": "0cb8c63458cbe21bbe1ad66543f73a04"
        },
        source: "composite",
        "source-layer": "road",
        minzoom: 5,
        filter: [
          "all",
          ["!in", "structure", "bridge", "tunnel"],
          ["==", "class", "trunk"]
        ],
        layout: {
          "line-cap": "round",
          "line-join": "round"
        },
        paint: {
          "line-width": getStrokeSize(baseStrokeSize * 0.75),
          "line-color": foregroundColor,
          "line-opacity": 1
        }
      },
      {
        id: "road-motorway", // major road
        minzoom: 6,
        type: "line",
        metadata: {
          "mapbox:group": "0cb8c63458cbe21bbe1ad66543f73a04"
        },
        source: "composite",
        "source-layer": "road",
        filter: [
          "all",
          ["!in", "structure", "bridge", "tunnel"],
          ["==", "class", "motorway"]
        ],
        layout: {
          "line-cap": "round",
          "line-join": "round",
          visibility: "visible"
        },
        paint: {
          "line-width": getStrokeSize(baseStrokeSize),
          "line-color": foregroundColor,
          "line-opacity": 1
        }
      },
      {
        id: "road-rail-case",
        type: "line",
        metadata: {
          "mapbox:group": "0cb8c63458cbe21bbe1ad66543f73a04"
        },
        source: "composite",
        "source-layer": "road",
        minzoom: 13,
        filter: [
          "all",
          ["!in", "structure", "bridge", "tunnel"],
          ["in", "class", "major_rail", "minor_rail"]
        ],
        layout: {
          "line-join": "miter",
          "line-cap": "square"
        },
        paint: {
          "line-color": foregroundColor,
          "line-width": getStrokeSize(baseStrokeSize * 0.75),
          "line-dasharray": [0.25, 10]
        }
      },
      {
        id: "road-rail",
        type: "line",
        metadata: {
          "mapbox:group": "0cb8c63458cbe21bbe1ad66543f73a04"
        },
        source: "composite",
        "source-layer": "road",
        minzoom: 13,
        filter: [
          "all",
          ["!in", "structure", "bridge", "tunnel"],
          ["in", "class", "major_rail", "minor_rail"]
        ],
        layout: {
          "line-join": "round"
        },
        paint: {
          "line-color": foregroundColor,
          "line-width": getStrokeSize(baseStrokeSize * 0.75)
        }
      },
      {
        id: "bridge-pedestrian-case",
        type: "line",
        metadata: {
          "mapbox:group": "f423319a6dab2ed463382f7e7edc5da1"
        },
        source: "composite",
        "source-layer": "road",
        minzoom: 13,
        filter: [
          "all",
          ["==", "$type", "LineString"],
          ["all", ["==", "class", "pedestrian"], ["==", "structure", "bridge"]]
        ],
        layout: {
          "line-join": "round"
        },
        paint: {
          "line-width": getStrokeSize(baseStrokeSize * 0.75),
          "line-color": foregroundColor,
          "line-opacity": {
            base: 1,
            stops: [[13, 0], [14, 1]]
          }
        }
      },
      {
        id: "bridge-service-link-track-case",
        type: "line",
        metadata: {
          "mapbox:group": "f423319a6dab2ed463382f7e7edc5da1"
        },
        source: "composite",
        "source-layer": "road",
        minzoom: 14,
        filter: [
          "all",
          ["!=", "type", "trunk_link"],
          ["==", "structure", "bridge"],
          ["in", "class", "link", "service", "track"]
        ],
        layout: {
          "line-join": "round"
        },
        paint: {
          "line-width": getStrokeSize(baseStrokeSize * 0.75),
          "line-color": foregroundColor,
          "line-gap-width": {
            base: 1.5,
            stops: [[14, 0.5], [18, 12]]
          }
        }
      },
      {
        id: "bridge-street-case",
        type: "line",
        metadata: {
          "mapbox:group": "f423319a6dab2ed463382f7e7edc5da1"
        },
        source: "composite",
        "source-layer": "road",
        minzoom: 11,
        filter: [
          "all",
          ["==", "structure", "bridge"],
          ["in", "class", "street", "street_limited"]
        ],
        layout: {
          "line-join": "round"
        },
        paint: {
          "line-width": getStrokeSize(baseStrokeSize * 0.75),
          "line-color": foregroundColor,
          "line-opacity": {
            base: 1,
            stops: [[13, 0], [14, 1]]
          },
          "line-gap-width": {
            base: 1.5,
            stops: [[13, 0], [14, 2], [18, 18]]
          }
        }
      },
      {
        id: "bridge-secondary-tertiary-case",
        type: "line",
        metadata: {
          "mapbox:group": "f423319a6dab2ed463382f7e7edc5da1"
        },
        source: "composite",
        "source-layer": "road",
        filter: [
          "all",
          ["==", "structure", "bridge"],
          ["in", "class", "secondary", "tertiary"]
        ],
        layout: {
          "line-join": "round"
        },
        paint: {
          "line-width": getStrokeSize(baseStrokeSize * 0.75),
          "line-color": foregroundColor,
          "line-gap-width": {
            base: 1.5,
            stops: [[8.5, 0.5], [10, 0.75], [18, 26]]
          },
          "line-translate": [0, 0]
        }
      },
      {
        id: "bridge-primary-case",
        type: "line",
        metadata: {
          "mapbox:group": "f423319a6dab2ed463382f7e7edc5da1"
        },
        source: "composite",
        "source-layer": "road",
        filter: [
          "all",
          ["==", "class", "primary"],
          ["==", "structure", "bridge"]
        ],
        layout: {
          "line-join": "round"
        },
        paint: {
          "line-width": getStrokeSize(baseStrokeSize * 0.75),
          "line-color": foregroundColor,
          "line-gap-width": {
            base: baseStrokeSize,
            stops: [
              [8.5, baseStrokeSize / 1.5],
              [10, baseStrokeSize * 0.75],
              [18, baseStrokeSize]
            ]
          },
          "line-translate": [0, 0]
        }
      },
      {
        id: "bridge-trunk-link-case",
        type: "line",
        metadata: {
          "mapbox:group": "f423319a6dab2ed463382f7e7edc5da1"
        },
        source: "composite",
        "source-layer": "road",
        minzoom: 13,
        filter: [
          "all",
          ["==", "structure", "bridge"],
          ["==", "type", "trunk_link"]
        ],
        layout: {
          "line-join": "round"
        },
        paint: {
          "line-width": getStrokeSize(baseStrokeSize * 0.75),
          "line-color": foregroundColor,
          "line-gap-width": {
            base: 1.5,
            stops: [[12, 0.5], [14, 1], [18, 1]]
          },
          "line-opacity": {
            base: 1,
            stops: [[10.99, 0], [11, 1]]
          }
        }
      },
      {
        id: "bridge-motorway-link-case",
        type: "line",
        metadata: {
          "mapbox:group": "f423319a6dab2ed463382f7e7edc5da1"
        },
        source: "composite",
        "source-layer": "road",
        minzoom: 13,
        filter: [
          "all",
          ["<=", "layer", 1],
          ["==", "class", "motorway_link"],
          ["==", "structure", "bridge"]
        ],
        layout: {
          "line-join": "round"
        },
        paint: {
          "line-width": getStrokeSize(baseStrokeSize * 0.75),
          "line-color": foregroundColor,
          "line-gap-width": {
            base: 1.5,
            stops: [[12, 0.5], [14, 2], [18, 18]]
          },
          "line-opacity": 1
        }
      },
      {
        id: "bridge-trunk-case",
        type: "line",
        metadata: {
          "mapbox:group": "f423319a6dab2ed463382f7e7edc5da1"
        },
        source: "composite",
        "source-layer": "road",
        filter: [
          "all",
          ["==", "class", "trunk"],
          ["==", "structure", "bridge"]
        ],
        layout: {
          "line-join": "round"
        },
        paint: {
          "line-width": getStrokeSize(baseStrokeSize * 0.75),
          "line-color": foregroundColor,
          "line-gap-width": {
            base: 1.5,
            stops: [[5, 0.75], [18, 32]]
          }
        }
      },
      {
        id: "bridge-motorway-case",
        type: "line",
        metadata: {
          "mapbox:group": "f423319a6dab2ed463382f7e7edc5da1"
        },
        source: "composite",
        "source-layer": "road",
        filter: [
          "all",
          ["==", "class", "motorway"],
          ["==", "structure", "bridge"]
        ],
        layout: {
          "line-join": "round"
        },
        paint: {
          "line-width": getStrokeSize(baseStrokeSize * 0.75),
          "line-color": foregroundColor,
          "line-gap-width": {
            base: 1.5,
            stops: [[5, 0.75], [18, 32]]
          }
        }
      },
      {
        id: "bridge-trunk-link",
        type: "line",
        metadata: {
          "mapbox:group": "f423319a6dab2ed463382f7e7edc5da1"
        },
        source: "composite",
        "source-layer": "road",
        minzoom: 13,
        filter: [
          "all",
          ["!in", "layer", 2, 3, 4, 5],
          ["==", "structure", "bridge"],
          ["==", "type", "trunk_link"]
        ],
        layout: {
          "line-cap": "round",
          "line-join": "round"
        },
        paint: {
          "line-width": getStrokeSize(baseStrokeSize * 0.75),
          "line-color": foregroundLight
        }
      },
      {
        id: "bridge-motorway-link",
        type: "line",
        metadata: {
          "mapbox:group": "f423319a6dab2ed463382f7e7edc5da1"
        },
        source: "composite",
        "source-layer": "road",
        minzoom: 13,
        filter: [
          "all",
          ["!in", "layer", 2, 3, 4, 5],
          ["==", "class", "motorway_link"],
          ["==", "structure", "bridge"]
        ],
        layout: {
          "line-cap": "round",
          "line-join": "round"
        },
        paint: {
          "line-width": getStrokeSize(baseStrokeSize * 0.75),
          "line-color": foregroundColor
        }
      },
      {
        id: "bridge-pedestrian",
        type: "line",
        metadata: {
          "mapbox:group": "f423319a6dab2ed463382f7e7edc5da1"
        },
        source: "composite",
        "source-layer": "road",
        minzoom: 13,
        filter: [
          "all",
          ["==", "$type", "LineString"],
          ["all", ["==", "class", "pedestrian"], ["==", "structure", "bridge"]]
        ],
        layout: {
          "line-join": "round"
        },
        paint: {
          "line-width": getStrokeSize(baseStrokeSize * 0.75),
          "line-color": foregroundLight,
          "line-opacity": 1,
          "line-dasharray": {
            base: 1,
            stops: [[14, [1, 0]], [15, [1.5, 0.4]], [16, [1, 0.2]]]
          }
        }
      },
      {
        id: "bridge-service-link-track",
        type: "line",
        metadata: {
          "mapbox:group": "f423319a6dab2ed463382f7e7edc5da1"
        },
        source: "composite",
        "source-layer": "road",
        minzoom: 14,
        filter: [
          "all",
          ["!=", "type", "trunk_link"],
          ["==", "structure", "bridge"],
          ["in", "class", "link", "service", "track"]
        ],
        layout: {
          "line-cap": "round",
          "line-join": "round"
        },
        paint: {
          "line-width": getStrokeSize(baseStrokeSize * 0.75),
          "line-color": foregroundLight
        }
      },
      {
        id: "bridge-street",
        type: "line",
        metadata: {
          "mapbox:group": "f423319a6dab2ed463382f7e7edc5da1"
        },
        source: "composite",
        "source-layer": "road",
        minzoom: 11,
        filter: [
          "all",
          ["==", "structure", "bridge"],
          ["in", "class", "street", "street_limited"]
        ],
        layout: {
          "line-cap": "round",
          "line-join": "round"
        },
        paint: {
          "line-width": getStrokeSize(baseStrokeSize * 0.75),
          "line-color": foregroundLight,
          "line-opacity": {
            base: 1,
            stops: [[13, 0], [14, 1]]
          }
        }
      },
      {
        id: "bridge-secondary-tertiary",
        type: "line",
        metadata: {
          "mapbox:group": "f423319a6dab2ed463382f7e7edc5da1"
        },
        source: "composite",
        "source-layer": "road",
        filter: [
          "all",
          ["==", "structure", "bridge"],
          ["in", "type", "secondary", "tertiary"]
        ],
        layout: {
          "line-cap": "round",
          "line-join": "round"
        },
        paint: {
          "line-width": getStrokeSize(baseStrokeSize * 0.75),
          "line-color": foregroundLight,
          "line-opacity": {
            base: 1.2,
            stops: [[5, 0], [5.5, 1]]
          }
        }
      },
      {
        id: "bridge-primary",
        type: "line",
        metadata: {
          "mapbox:group": "f423319a6dab2ed463382f7e7edc5da1"
        },
        source: "composite",
        "source-layer": "road",
        filter: [
          "all",
          ["==", "structure", "bridge"],
          ["==", "type", "primary"]
        ],
        layout: {
          "line-cap": "round",
          "line-join": "round"
        },
        paint: {
          "line-width": getStrokeSize(baseStrokeSize),
          "line-color": foregroundLight,
          "line-opacity": {
            base: 1.2,
            stops: [[5, 0], [5.5, 1]]
          }
        }
      },
      {
        id: "bridge-trunk",
        type: "line",
        metadata: {
          "mapbox:group": "f423319a6dab2ed463382f7e7edc5da1"
        },
        source: "composite",
        "source-layer": "road",
        filter: [
          "all",
          ["!in", "layer", 2, 3, 4, 5],
          ["==", "class", "trunk"],
          ["==", "structure", "bridge"]
        ],
        layout: {
          "line-cap": "round",
          "line-join": "round"
        },
        paint: {
          "line-width": getStrokeSize(baseStrokeSize * 0.75),
          "line-color": foregroundLight
        }
      },
      {
        id: "bridge-motorway",
        type: "line",
        metadata: {
          "mapbox:group": "f423319a6dab2ed463382f7e7edc5da1"
        },
        source: "composite",
        "source-layer": "road",
        filter: [
          "all",
          ["<", "layer", 2],
          ["==", "class", "motorway"],
          ["==", "structure", "bridge"]
        ],
        layout: {
          "line-cap": "round",
          "line-join": "round"
        },
        paint: {
          "line-width": getStrokeSize(baseStrokeSize),
          "line-color": foregroundColor
        }
      },
      {
        id: "bridge-rail",
        type: "line",
        metadata: {
          "mapbox:group": "f423319a6dab2ed463382f7e7edc5da1"
        },
        source: "composite",
        "source-layer": "road",
        minzoom: 13,
        filter: [
          "all",
          ["==", "structure", "bridge"],
          ["in", "class", "major_rail", "minor_rail"]
        ],
        layout: {
          "line-join": "round",
          visibility: "visible"
        },
        paint: {
          "line-color": foregroundColor,
          "line-width": getStrokeSize(baseStrokeSize * 0.75)
        }
      },
      {
        id: "bridge-trunk-link-2-case",
        type: "line",
        metadata: {
          "mapbox:group": "f423319a6dab2ed463382f7e7edc5da1"
        },
        source: "composite",
        "source-layer": "road",
        minzoom: 13,
        filter: [
          "all",
          ["==", "structure", "bridge"],
          ["==", "type", "trunk_link"],
          [">=", "layer", 2]
        ],
        layout: {
          "line-join": "round"
        },
        paint: {
          "line-width": getStrokeSize(baseStrokeSize * 0.75),
          "line-color": foregroundColor,
          "line-gap-width": {
            base: 1.5,
            stops: [[12, 0.5], [14, 1], [18, 1]]
          },
          "line-opacity": {
            base: 1,
            stops: [[10.99, 0], [11, 1]]
          }
        }
      },
      {
        id: "bridge-motorway-link-2-case",
        type: "line",
        metadata: {
          "mapbox:group": "f423319a6dab2ed463382f7e7edc5da1"
        },
        source: "composite",
        "source-layer": "road",
        minzoom: 13,
        filter: [
          "all",
          ["==", "class", "motorway_link"],
          ["==", "structure", "bridge"],
          [">=", "layer", 2]
        ],
        layout: {
          "line-join": "round"
        },
        paint: {
          "line-width": getStrokeSize(baseStrokeSize * 0.75),
          "line-color": foregroundColor,
          "line-gap-width": {
            base: 1.5,
            stops: [[12, 0.5], [14, 2], [18, 18]]
          },
          "line-opacity": 1
        }
      },
      {
        id: "bridge-trunk-2-case",
        type: "line",
        metadata: {
          "mapbox:group": "f423319a6dab2ed463382f7e7edc5da1"
        },
        source: "composite",
        "source-layer": "road",
        filter: [
          "all",
          ["==", "class", "trunk"],
          ["==", "structure", "bridge"],
          [">=", "layer", 2]
        ],
        layout: {
          "line-join": "round"
        },
        paint: {
          "line-width": getStrokeSize(baseStrokeSize * 0.75),
          "line-color": foregroundColor,
          "line-gap-width": {
            base: 1.5,
            stops: [[5, 0.75], [18, 32]]
          }
        }
      },
      {
        id: "bridge-motorway-2-case",
        type: "line",
        metadata: {
          "mapbox:group": "f423319a6dab2ed463382f7e7edc5da1"
        },
        source: "composite",
        "source-layer": "road",
        filter: [
          "all",
          ["==", "class", "motorway"],
          ["==", "structure", "bridge"],
          [">=", "layer", 2]
        ],
        layout: {
          "line-join": "round"
        },
        paint: {
          "line-width": getStrokeSize(baseStrokeSize * 0.75),
          "line-color": foregroundColor,
          "line-gap-width": {
            base: 1.5,
            stops: [[5, 0.75], [18, 32]]
          }
        }
      },
      {
        id: "bridge-trunk-link-2",
        type: "line",
        metadata: {
          "mapbox:group": "f423319a6dab2ed463382f7e7edc5da1"
        },
        source: "composite",
        "source-layer": "road",
        minzoom: 13,
        filter: [
          "all",
          ["==", "structure", "bridge"],
          ["==", "type", "trunk_link"],
          [">=", "layer", 2]
        ],
        layout: {
          "line-cap": "round",
          "line-join": "round"
        },
        paint: {
          "line-width": getStrokeSize(baseStrokeSize * 0.75),
          "line-color": foregroundColor
        }
      },
      {
        id: "bridge-motorway-link-2",
        type: "line",
        metadata: {
          "mapbox:group": "f423319a6dab2ed463382f7e7edc5da1"
        },
        source: "composite",
        "source-layer": "road",
        minzoom: 13,
        filter: [
          "all",
          ["==", "class", "motorway_link"],
          ["==", "structure", "bridge"],
          [">=", "layer", 2]
        ],
        layout: {
          "line-cap": "round",
          "line-join": "round"
        },
        paint: {
          "line-width": getStrokeSize(baseStrokeSize * 0.75),
          "line-color": foregroundColor
        }
      },
      {
        id: "bridge-trunk-2",
        type: "line",
        metadata: {
          "mapbox:group": "f423319a6dab2ed463382f7e7edc5da1"
        },
        source: "composite",
        "source-layer": "road",
        filter: [
          "all",
          ["==", "class", "trunk"],
          ["==", "structure", "bridge"],
          [">=", "layer", 2]
        ],
        layout: {
          "line-cap": "round",
          "line-join": "round"
        },
        paint: {
          "line-width": getStrokeSize(baseStrokeSize * 0.75),
          "line-color": foregroundLight
        }
      },
      {
        id: "bridge-motorway-2",
        type: "line",
        metadata: {
          "mapbox:group": "f423319a6dab2ed463382f7e7edc5da1"
        },
        source: "composite",
        "source-layer": "road",
        filter: [
          "all",
          ["==", "class", "motorway"],
          ["==", "structure", "bridge"],
          [">=", "layer", 2]
        ],
        layout: {
          "line-cap": "round",
          "line-join": "round"
        },
        paint: {
          "line-width": getStrokeSize(baseStrokeSize * 0.75),
          "line-color": foregroundColor
        }
      },
      {
        id: "border-admin-3-4",
        type: "line",
        metadata: {
          "mapbox:group": "4a585425cb0cc2af93b4cdedda86461d"
        },
        source: "composite",
        "source-layer": "admin",
        filter: ["all", ["==", "maritime", 0], [">=", "admin_level", 3]],
        layout: {
          "line-join": "round",
          "line-cap": "round",
          visibility: "visible"
        },
        paint: {
          "line-dasharray": {
            base: 1,
            stops: [[6, [2, 0]], [7, [2, 2, 6, 2]]]
          },
          "line-width": getStrokeSize(baseStrokeSize * 0.75),
          "line-color": foregroundColor
        }
      },
      {
        id: "border-admin-4", // state borders
        type: "line",
        metadata: {
          "mapbox:group": "4a585425cb0cc2af93b4cdedda86461d"
        },
        source: "composite",
        "source-layer": "admin",
        filter: [
          "all",
          ["==", "admin_level", 4],
          ["==", "disputed", 0],
          ["==", "maritime", 0]
        ],

        layout: {
          "line-join": "round",
          "line-cap": "round",
          visibility: "visible"
        },
        paint: {
          "line-width": getStrokeSize(baseStrokeSize * 0.75),
          "line-color": foregroundColor
        }
      },
      {
        id: "border-admin-2", // country borders
        type: "line",
        metadata: {
          "mapbox:group": "4a585425cb0cc2af93b4cdedda86461d"
        },
        source: "composite",
        "source-layer": "admin",
        filter: [
          "all",
          ["==", "admin_level", 2],
          ["==", "disputed", 0],
          ["==", "maritime", 0]
        ],

        layout: {
          "line-join": "round",
          "line-cap": "round",
          visibility: "visible"
        },
        paint: {
          "line-width": getStrokeSize(baseStrokeSize * 0.75),
          "line-color": foregroundColor
        }
      },

      {
        id: "border-admin-2-dispute",
        type: "line",
        metadata: {
          "mapbox:group": "4a585425cb0cc2af93b4cdedda86461d"
        },
        source: "composite",
        "source-layer": "admin",
        minzoom: 1,
        filter: [
          "all",
          ["==", "admin_level", 2],
          ["==", "disputed", 1],
          ["==", "maritime", 0]
        ],
        layout: {
          "line-join": "round"
        },
        paint: {
          "line-dasharray": [1.5, 1.5],
          "line-color": foregroundColor,
          "line-width": getStrokeSize(baseStrokeSize * 0.75)
        }
      }
    ],
    created: "2017-07-31T16:13:38.918Z",
    id: "cj5sctll40mwn2smt9y7e8su1",
    modified: "2017-07-31T16:19:30.468Z",
    owner: "wmdmark",
    visibility: "private",
    draft: false
  };
};
