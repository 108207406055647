export const MAPBOX_TOKEN =
  "pk.eyJ1Ijoid21kbWFyayIsImEiOiJjajQxbnJzYmgwZTNnMnhva3phenc1cTE0In0.vDy3bdpqrTD2nh0--P0o8A"

export const DEBUG = process.env.NODE_ENV === "development"

export const STRIPE_TOKEN = DEBUG
  ? "pk_test_WWAHF7IzOtdCbQ6ZzW44n6iU"
  : "pk_live_vhAG7fXp7YnEYpFR2PfmsuYc"

export const POSTER_PRICE = 35

export const getPosterPrices = state => {
  if (state.size === "18x18") {
    return { poster: 35, posterWithFrame: 45 }
  } else {
    return { poster: 40, posterWithFrame: 55 }
  }
}
