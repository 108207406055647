import React from "react";

const Logo = props => (
  <svg
    viewBox="0 0 219.01 219.01"
    fill="currentColor"
    stroke="currentColor"
    {...props}
  >
    <defs />
    <title>Asset 3</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className="cls-1"
          d="M109.51 219A109.51 109.51 0 1 1 219 109.51 109.63 109.63 0 0 1 109.51 219zm0-205.08a95.57 95.57 0 1 0 95.57 95.57 95.68 95.68 0 0 0-95.57-95.55z"
        />
        <path
          className="cls-1"
          d="M209.42 112.65H11.61a9.29 9.29 0 0 1 0-18.58h197.81a9.29 9.29 0 1 1 0 18.58z"
        />
        <path
          className="cls-1"
          d="M71.93 209.21a10.46 10.46 0 0 1-9.67-14.41l38.59-94.43a10.45 10.45 0 0 1 19.35 7.91l-38.59 94.43a10.46 10.46 0 0 1-9.68 6.5zM199.72 81.68H19.19a7 7 0 1 1 0-13.94h180.53a7 7 0 1 1 0 13.94z"
        />
      </g>
    </g>
  </svg>
);

export default Logo;
