import React from "react";
import styled from "styled-components";
import {
  baseInnerFontSize,
  baseOuterFontSize,
  baseScale,
  calcFontCircleRadius
} from "./data";
import CircleText from "./CircleText";

// FIX ME

const CircleTextContainer = styled.div`
  position: absolute;
  top: calc(50% - ${p => p.radius}px);
  left: calc(50% - ${p => p.radius}px);
  width: ${p => p.radius * 2}px;
  height: ${p => p.radius * 2}px;
  z-index: 101;
  pointer-events: none;
  box-sizing: border-box;
  // border: 1px solid #fff;
`;

const PosterTextCircle = ({
  scale,
  radius,
  textTop,
  textBottom,
  foregroundColor,
  width,
  textStyle
}) => {
  const circleRadius = calcFontCircleRadius(radius, scale);
  return (
    <CircleTextContainer radius={circleRadius}>
      {textTop ? (
        <CircleText
          radius={radius}
          text={textTop}
          scale={scale}
          color={foregroundColor}
          textStyle={textStyle}
        />
      ) : null}
      {textBottom ? (
        <CircleText
          radius={radius}
          text={textBottom}
          scale={scale}
          color={foregroundColor}
          textStyle={textStyle}
          inverted
        />
      ) : null}
    </CircleTextContainer>
  );
};

export default PosterTextCircle;
