import React from "react"
import color from "color"
import { getMapStyle } from "../../utils/map-styles"
import styled from "styled-components"
import Map from "../map/Map"

const MapBGContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  background-color: ${p => p.backgroundColor};
  opacity: 0.35;
  z-index: 0;
  pointer-events: none;
  &::after {
    z-index: 20;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    background-color: ${p =>
      color(p.backgroundColor)
        .alpha(0.5)
        .toString()};
    /* background: radial-gradient(
      circle,
      transparent 300%,
      ${p => p.backgroundColor}
    ); */
  }
`

const MapBG = ({ width, height, zoomOffset, posterState }) => {
  const style = getMapStyle({ ...posterState })
  return (
    <MapBGContainer backgroundColor={posterState.backgroundColor}>
      <Map
        width={width}
        height={height}
        location={posterState.location}
        zoom={posterState.zoom + zoomOffset}
        style={style}
        transitionIn={false}
        overlayImage={false}
        animateMove={false}
        readonly={true}
        mapScale={1}
        scale={0}
        pitch={45}
      />
    </MapBGContainer>
  )
}

MapBG.defaultProps = {
  zoomOffset: 0.5
}

export default MapBG
