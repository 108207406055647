import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

const getMetaTags = meta => {
  const image = require("../assets/thumb.jpg")
  return [
    { itemprop: "name", content: meta.title },
    { itemprop: "description", content: meta.description },
    { itemprop: "image", content: image },
    { name: "description", content: meta.description },
    { name: "twitter:card", content: "summary_large_image" },
    { name: "twitter:site", content: "@tiltmaps" },
    { name: "twitter:title", content: `${meta.title} | TiltMaps.com` },
    { name: "twitter:description", content: meta.description },
    { name: "twitter:creator", content: "@tiltmaps" },
    {
      name: "twitter:image:src",
      content: image
    },
    { name: "og:title", content: `${meta.title} | TiltMaps.com` },
    { name: "og:image", content: image },
    { name: "og:description", content: meta.description },
    { name: "og:site_name", content: "TiltMaps" }
  ]
}

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={getMetaTags(data.site.siteMetadata)}
        >
          <html lang="en" />
        </Helmet>
        <div style={{ width: "100%", height: "100%" }}>{children}</div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
