export const isLocationDifferentEnough = (loc, nextLoc, precision = 4) => {
  if (!loc || !nextLoc) {
    console.warn("invalid params for isLocationDifferentEnough", loc, nextLoc)
    return false
  }
  const latChanged =
    loc.lat.toFixed(precision) !== nextLoc.lat.toFixed(precision)
  const lngChanged =
    loc.lng.toFixed(precision) !== nextLoc.lng.toFixed(precision)
  return latChanged || lngChanged
}

// var tilebelt = require("@mapbox/tilebelt")
// var getPixels = require("get-pixels")

// //return array with height data from img
// export const getHeightData = (img, scale = 1) => {
//   var canvas = document.createElement("canvas")
//   canvas.width = img.width
//   canvas.height = img.height
//   var context = canvas.getContext("2d")

//   var size = img.width * img.height
//   var data = new Float32Array(size)

//   context.drawImage(img, 0, 0)

//   for (var i = 0; i < size; i++) {
//     data[i] = 0
//   }

//   var imgd = context.getImageData(0, 0, img.width, img.height)
//   var pix = imgd.data

//   var j = 0
//   for (var i = 0; i < pix.length; i += 4) {
//     var all = pix[i] + pix[i + 1] + pix[i + 2]
//     data[j++] = all / (12 * scale)
//   }

//   return data
// }

// export const getTerrainURL = latLng => {
//   var domain = "https://api.mapbox.com/v4/"
//   var source = `mapbox.terrain-rgb/${tile[2]}/${tile[0]}/${tile[1]}.pngraw`
//   return `${domain}${source}?access_token=${TOKEN}`
// }

// export const createElevationMesh = async (latLng, mapTexture) => {
//   const tileSrc = getTerrainURL(latLng)
//   const loadImg = new Image()
//   loadImg.onload = () => {
//     const heightData = getHeightData(loadImg)
//     // plane
//     var geometry = new THREE.PlaneGeometry(10, 10, 9, 9)
//     var texture = THREE.ImageUtils.loadTexture(mapTexture)
//     var material = new THREE.MeshLambertMaterial({ map: texture })
//     plane = new THREE.Mesh(geometry, material)

//     //set height of vertices
//     for (var i = 0; i < plane.geometry.vertices.length; i++) {
//       plane.geometry.vertices[i].z = data[i]
//     }

//     scene.add(plane)
//   }
//   loadImg.src = tileSrc
// }

// export const getElevation = (latLng, token) => {
//   return new Promise((resolve, reject) => {
//     var tf = tilebelt.pointToTileFraction(latLng[0], latLng[1], 20)
//     var tile = tf.map(Math.floor)
//     var domain = "https://api.mapbox.com/v4/"
//     var source = `mapbox.terrain-rgb/${tile[2]}/${tile[0]}/${tile[1]}.pngraw`
//     var url = `${domain}${source}?access_token=${token}`
//     getPixels(url, function(err, pixels) {
//       if (err) return reject(err)

//       var xp = tf[0] - tile[0]
//       var yp = tf[1] - tile[1]
//       var x = Math.floor(xp * pixels.shape[0])
//       var y = Math.floor(yp * pixels.shape[1])

//       var R = pixels.get(x, y, 0)
//       var G = pixels.get(x, y, 1)
//       var B = pixels.get(x, y, 2)

//       var height = -10000 + (R * 256 * 256 + G * 256 + B) * 0.1

//       return resolve(height)
//     })
//   })
// }
