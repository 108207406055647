import React from "react"
import Button from "@material-ui/core/Button"
import Color from "color"
import { useUITheme } from "./theme"

const borderRadius = 24

const ConnectedButton = props => {
  const {
    disabled,
    submitting,
    foregroundColor,
    backgroundColor,
    style,
    type,
    border,
    children
  } = props

  const [theme] = useUITheme()

  const fg = foregroundColor || theme.palette.text.primary
  const bg = backgroundColor || theme.palette.background.paper

  const buttonStyles =
    type === "contained"
      ? {
          borderRadius,
          boxShadow: "0px 0px 20px rgba(0,0,0,.35)",
          backgroundColor: bg,
          color: fg
        }
      : { color: fg }

  if (border) {
    buttonStyles.border = `2px solid ${new Color(bg).darken(0.3).toString()}`
  }

  return (
    <Button
      variant={type}
      id={props.id || props.name}
      disabled={disabled || submitting}
      onClick={props.onClick}
      size={props.size}
      type={props.type}
      style={{ ...buttonStyles, ...style, opacity: disabled ? 0.7 : 1 }}
    >
      {children || props.label}
    </Button>
  )
}

ConnectedButton.defaultProps = {
  type: "contained",
  borderRadius: 12,
  size: "medium"
}

export default ConnectedButton
